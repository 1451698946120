<template>
  <b-card>
    <b-table :fields="fields" :items="items">
      <template #cell(log_message)="data">
        <p v-html="data.item.log_message"></p>
      </template>
      <template #cell(activity_time)="data">
        <span>{{ data.item.activity_time | formatdate }}</span>
        <!-- <span>{{ new Date(data.item.activity_time).toLocaleString() }}</span> -->
      </template>
    </b-table>
  </b-card>
</template>
<script>
import moment from "moment";
import { BCard, BTable, BBadge } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BTable,
    BBadge,
  },
  data() {
    return {
      organization: this.$store.state.app.org_id,
      items: [],
      fields: [
        // { key: "index", label: "#" },
        { key: "log_message", label: "Log Message" },
        // { key: "user_email", label: "User Name" },
        { key: "activity_on", label: "Activity on" },
        // { key: "asset_name", label: "Asset Name" },
        // { key: "ref_id", label: "Ref ID" },
        { key: "activity_time", label: "Activity Time" },
      ],
    };
  },
  created: function () {
    this.load();
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "logs/v1/logs/report-logs?report_id=" +
          this.$route.params.id,
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
      });
    },
  },
};
</script>